import { Navigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks/hooks";
import * as Yup from "yup";

import routes from "api/routes";

import {
  AuthFormHeader,
  AuthForm,
  AuthFormFooter,
  AuthContainer
} from "components/authComponents";

import { isEmail } from "utils/isEmail";
import { GetCodeInitialValues } from "types/AuthTypes";

const formInitialValues: GetCodeInitialValues = {
  code: ""
};

const formSchema = Yup.object().shape({
  code: Yup.string()
    .length(6, "Код подтверждения указан не верно")
    .required("Код подтверждения указан не верно")
});

const GetCode: React.FC = () => {
  const emailOrPhone = useAppSelector((state) => state.user.emailOrPhone);

  if (!emailOrPhone) {
    return <Navigate to={routes.auth.contact.url} replace />;
  }

  return (
    <AuthContainer>
      <AuthFormHeader
        title="Авторизация пользователя"
        text={
          isEmail(emailOrPhone)
            ? `Введите код подтверждения из E-mail сообщения отправленного на почту ${emailOrPhone}`
            : `Введите код подтверждения из SMS-сообщения, отправленного на номер ${emailOrPhone}`
        }
      />
      <AuthForm
        initialValues={formInitialValues}
        schema={formSchema}
        formType="getCode"
      />
      <AuthFormFooter />
    </AuthContainer>
  );
};

export default GetCode;
