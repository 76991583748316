import { Field, Form, FormikProps } from "formik";

import {
  AuthInput,
  AuthButton,
  AuthFormError
} from "components/authComponents";
import { FormFields } from "components/ui";

import { RecoveryInitialValues } from "types/AuthTypes";

export const RecoveryFormFields: React.FC<
  FormikProps<RecoveryInitialValues>
> = (props) => {
  const { values, setFieldValue, errors, isValid, dirty } = props;

  return (
    <Form>
      <FormFields>
        <Field
          name="emailOrPhone"
          id="emailOrPhone"
          placeholder="E-mail или номер телефона"
          type="emailOrPhone"
          value={values.emailOrPhone}
          onChange={(value: string) => {
            setFieldValue("emailOrPhone", value);
          }}
          required
          component={AuthInput}
        />

        <AuthFormError errors={errors} />
      </FormFields>

      <AuthButton text="Отправить" disabled={!dirty || !isValid} />
    </Form>
  );
};
