import { createSlice } from "@reduxjs/toolkit";

interface AuthSlice {
  isAuth: boolean;
}

const initialState: AuthSlice = {
  isAuth: false,
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setAuth: (state) => {
      state.isAuth = true;
    },
    deleteAuth: (state) => {
      state.isAuth = false;
    },
  },
});

export const { setAuth, deleteAuth } = authSlice.actions;
export default authSlice.reducer;
