import { PropsWithChildren } from "react";
import styled from "styled-components";

import { MOBILE } from "styles/variables";

type FormFieldsProps = {
  gap?: string;
  marginBottom?: string;
};

export const FormFields: React.FC<PropsWithChildren<FormFieldsProps>> = (
  props
) => {
  const { children, ...rest } = props;

  return <FormFieldsWrapper {...rest}>{children}</FormFieldsWrapper>;
};

const FormFieldsWrapper = styled.div<FormFieldsProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || "26px"};
  margin-bottom: ${({ marginBottom }) => marginBottom || "100px"};

  @media screen and (max-width: ${MOBILE}) {
    gap: 6px;
    margin-bottom: ${({ marginBottom }) => marginBottom || "50px"};
  }
`;
