import { CSSProperties, PropsWithChildren } from "react";
import styled from "styled-components";

import { BLACK_COLOR } from "styles/variables";

export type TitleTypes = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface TitleProps {
  type: TitleTypes;
  color?: string;
  style?: CSSProperties;
  className?: string;
}

export const Title: React.FC<PropsWithChildren<TitleProps>> = (props) => {
  const { children, ...rest } = props;

  return <TitleElement {...rest}>{children}</TitleElement>;
};

const TitleElement = styled.div<TitleProps>`
  font-weight: 700;
  color: ${({ color }) => color || BLACK_COLOR};
  font-size: ${({ type }) => {
    switch (type) {
      case "h1":
        return "35px";
      case "h2":
        return "24px";
      case "h3":
        return "18px";
      case "h4":
        return "16px";
      case "h5":
        return "14px";
      case "h6":
        return "12px";
    }
  }};
  line-height: ${({ type }) => {
    switch (type) {
      case "h1":
        return "130%";
      case "h2":
        return "130%";
      case "h3":
        return "130%";
      case "h4":
        return "130%";
      case "h5":
        return "110%";
      case "h6":
        return "110%";
    }
  }};
  letter-spacing: ${({ type }) => {
    switch (type) {
      case "h1":
        return "3px";
      case "h2":
        return "3px";
      case "h3":
        return "3px";
      case "h4":
        return "2px";
      case "h5":
        return "1px";
      case "h6":
        return "1px";
    }
  }};
`;
