import { useMutation } from "@tanstack/react-query";

import routes from "api/routes";
import { callApi, ApiResponse, ErrorApiResponse } from "api/api";

import { useAppDispatch } from "redux/hooks/hooks";
import { setAuth } from "redux/slices/authSlice";

import { useQueryResultHandler } from "hooks/custom/useQueryResultHandler";

import {
  isContactLogin,
  isPasswordLogin,
  isTelegramLogin
} from "utils/checkType";
import { LoginParams } from "types/AuthTypes";

const getUrl = (params: LoginParams) => {
  if (isPasswordLogin(params)) {
    return routes.auth.login.api;
  } else if (isTelegramLogin(params)) {
    return routes.auth.telegram.api;
  } else if (isContactLogin(params)) {
    return routes.auth.contact.login.api;
  }

  return ""; // TODO: probably should handle an exception
};

const login = async (params: LoginParams) => {
  const url = getUrl(params);

  const config = {
    method: "POST",
    data: {
      ...params
    }
  };

  const response = await callApi<LoginParams>({
    url,
    config
  });

  return response;
};

export const useLogin = () => {
  const queryResultHandler = useQueryResultHandler();
  const dispatch = useAppDispatch();

  const responseHandler = (res: ApiResponse<LoginParams>) => {
    res.success && dispatch(setAuth());
    queryResultHandler(res);
  };

  const mutation = useMutation<
    ApiResponse<LoginParams>,
    ErrorApiResponse,
    LoginParams
  >((params: LoginParams) => login(params), {
    onSuccess: (res: ApiResponse<LoginParams>) => responseHandler(res),
    onError: (res: ErrorApiResponse) => responseHandler(res)
  });

  return mutation;
};
