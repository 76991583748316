import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserSlice {
  emailOrPhone: string;
}

const initialState: UserSlice = {
  emailOrPhone: ""
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserSlice>) => {
      state.emailOrPhone = action.payload.emailOrPhone;
    }
  }
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
