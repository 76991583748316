import * as Yup from "yup";
import { Formik } from "formik";
import styled from "styled-components";

import {
  useLogin,
  useRegister,
  useContactAuth,
  useRecoverPassword
} from "hooks/queries/auth";

import { useAppSelector } from "redux/hooks/hooks";

import { getFormFields } from "./getFormFields";
import {
  AuthInitialValues,
  EmailOrPhoneInitialValues,
  GetCodeInitialValues,
  LoginInitialValues,
  RecoveryInitialValues,
  RegistrationInitialValues,
  TelegramInitialValues
} from "types/AuthTypes";
import { MOBILE } from "styles/variables";

export type FormType =
  | "emailOrPhone"
  | "getCode"
  | "login"
  | "recovery"
  | "registration"
  | "telegram";

type AuthFormProps = {
  initialValues: AuthInitialValues;
  schema: Yup.AnyObjectSchema;
  formType: FormType;
};

export const AuthForm: React.FC<AuthFormProps> = ({
  initialValues,
  schema,
  formType
}) => {
  const user = useAppSelector((state) => state.user);
  const userTelegram = useAppSelector((state) => state.userTelegram.telegram);
  const { mutate: login } = useLogin();
  const { mutate: register } = useRegister();
  const { mutate: sendCode } = useContactAuth();
  const { mutate: recoverPassword } = useRecoverPassword();

  const handleSubmit = (values: AuthInitialValues) => {
    if (formType === "login") {
      const data = { ...values } as LoginInitialValues;

      login({
        email: data.login,
        password: data.password
      });
    } else if (formType === "registration") {
      const data = { ...values } as RegistrationInitialValues;

      register({
        name: data.firstName,
        surname: data.secondName,
        email: data.email,
        password: data.password,
        password_confirmation: data.passwordConfirmation
      });
    } else if (formType === "telegram") {
      const data = { ...values } as TelegramInitialValues;

      login({
        auth_date: userTelegram.auth_date,
        first_name: data.firstName,
        last_name: data.secondName,
        hash: userTelegram.hash,
        id: +data.telegramID,
        photo_url: userTelegram.photo_url,
        username: userTelegram.username
      });
    } else if (formType === "emailOrPhone") {
      const data = { ...values } as EmailOrPhoneInitialValues;

      sendCode({
        contact: data.emailOrPhone
      });
    } else if (formType === "getCode") {
      const data = { ...values } as GetCodeInitialValues;

      login({
        email: user.emailOrPhone,
        token: data.code
      });
    } else if (formType === "recovery") {
      const data = { ...values } as RecoveryInitialValues;

      recoverPassword({
        email: data.emailOrPhone
      });
    }
  };

  return (
    <AuthFormWrapper isLoginPage={formType === "login"}>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange
        onSubmit={handleSubmit}
      >
        {(props) => getFormFields(formType, props)}
      </Formik>
    </AuthFormWrapper>
  );
};

const AuthFormWrapper = styled.div<{ isLoginPage: boolean }>`
  padding: 30px 0 20px;

  @media screen and (max-width: ${MOBILE}) {
    padding: ${({ isLoginPage }) =>
      isLoginPage ? "16px 0 10px" : "16px 0 6px"};
  }
`;
