import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

import routes from "api/routes";

import { Text, CustomRouteLink } from "components/ui";

import {
  HEADER_HEIGHT,
  LIGHT_GRAY_COLOR,
  WHITE_COLOR,
  MOBILE,
  MOBILE_HEADER_HEIGHT
} from "styles/variables";

export const Header: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: MOBILE });

  return (
    <HeaderWrapper>
      <CustomRouteLink to={routes.auth.contact.to}>
        <Logo>
          <Text type={!isMobile ? "p3" : "p6"}>Логотип</Text>
        </Logo>
      </CustomRouteLink>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  padding: 10px 50px;
  height: ${HEADER_HEIGHT};
  background-color: ${WHITE_COLOR};
  box-shadow: 0px 3px 4px 0px #0000001a;
  z-index: 100;

  @media screen and (max-width: ${MOBILE}) {
    height: ${MOBILE_HEADER_HEIGHT};
    padding: 8px 10px;
  }
`;

const Logo = styled.div`
  width: fit-content;
  background-color: ${LIGHT_GRAY_COLOR};
  padding: 10px 36px 13px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: ${MOBILE}) {
    border-radius: 3px;
    padding: 7px 15px;
  }
`;
