import { useMutation } from "@tanstack/react-query";

import routes from "api/routes";
import { callApi, ApiResponse, ErrorApiResponse } from "api/api";

import { useQueryResultHandler } from "hooks/custom/useQueryResultHandler";

type ContactAuthParams = {
  contact: string;
};

const sendCode = async (params: ContactAuthParams) => {
  const url = routes.auth.contact.api;

  const config = {
    method: "POST",
    data: {
      ...params
    }
  };

  const response = await callApi<ContactAuthParams>({
    url,
    config
  });

  return response;
};

export const useContactAuth = () => {
  const queryResultHandler = useQueryResultHandler();

  const responseHandler = (res: ApiResponse<ContactAuthParams>) => {
    queryResultHandler(res, { error: routes.auth.contact.to });
  };

  const mutation = useMutation<
    ApiResponse<ContactAuthParams>,
    ErrorApiResponse,
    ContactAuthParams
  >((params: ContactAuthParams) => sendCode(params), {
    onSuccess: (res: ApiResponse<ContactAuthParams>) => responseHandler(res),
    onError: (res: ErrorApiResponse) => responseHandler(res)
  });

  return mutation;
};
