import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TelegramLoginParams } from "types/AuthTypes";

interface UserTelegramSlice {
  telegram: TelegramLoginParams;
}

const initialState: UserTelegramSlice = {
  telegram: {
    auth_date: 0,
    first_name: "",
    last_name: "",
    hash: "",
    id: 0,
    photo_url: "",
    username: ""
  }
};

export const userTelegramSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setTelegram: (state, action: PayloadAction<UserTelegramSlice>) => {
      state.telegram = action.payload.telegram;
    }
  }
});

export const { setTelegram } = userTelegramSlice.actions;
export default userTelegramSlice.reducer;
