import { useEffect, useState } from "react";

import { Input, InputIconProps, InputTypes } from "components/ui";

export type AuthInputTypes = InputTypes | "telegramID" | "emailOrPhone";

const getIconId = (type: AuthInputTypes, showPassword?: boolean) => {
  switch (type) {
    case "password":
      return showPassword ? "openEye" : "closeEye";
    case "telegramID":
    case "emailOrPhone":
    case "email":
      return "mail";
    default:
      return "";
  }
};

interface AuthInputProps {
  type: AuthInputTypes;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  required: boolean;
  disabled: boolean;
}

export const AuthInput: React.FC<AuthInputProps> = ({
  type,
  value,
  onChange,
  placeholder,
  required,
  disabled
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [icon, setIcon] = useState<InputIconProps>({
    iconId: ""
  });

  useEffect(() => {
    const handleIconClick = () => {
      setShowPassword(!showPassword);
    };

    const iconId = getIconId(type, showPassword);
    setIcon({
      iconId: iconId,
      onClick: type === "password" ? handleIconClick : undefined
    });
  }, [showPassword, type]);

  return (
    <Input
      type={
        showPassword
          ? "text"
          : (type.replace(/TelegramID|emailOrPhone/g, "text") as InputTypes)
      }
      icon={icon}
      placeholder={placeholder}
      value={value}
      required={required}
      disabled={disabled}
      onChange={onChange}
    />
  );
};
