import { FormikErrors } from "formik";
import styled from "styled-components";

import { Text } from "components/ui";

import { useAppSelector } from "redux/hooks/hooks";

import { AuthInitialValues } from "types/AuthTypes";
import { MOBILE, RED_COLOR } from "styles/variables";

const getErrorMessage = (errors: FormikErrors<AuthInitialValues>) => {
  const firstErrorMessage = Object.values(errors)[0];

  return firstErrorMessage;
};

type AuthFormErrorProps = {
  errors: FormikErrors<AuthInitialValues>;
};

export const AuthFormError: React.FC<AuthFormErrorProps> = ({ errors }) => {
  const isMobile = useAppSelector((state) => state.screen.isMobile);

  return (
    <Error>
      <Text type={!isMobile ? "p3" : "p4"} color={RED_COLOR}>
        {getErrorMessage(errors)}
      </Text>
    </Error>
  );
};

const Error = styled.div`
  height: 17px;
  position: relative;
  top: -6px;

  @media screen and (max-width: ${MOBILE}) {
    height: 12px;
    top: 0px;
  }
`;
