import { CSSProperties } from "react";
import styled from "styled-components";

import { InputIcon, InputIconProps } from "./InputIcon";

import {
  BLACK_COLOR,
  BLUE_COLOR,
  GRAY_COLOR,
  WHITE_COLOR,
  MOBILE
} from "styles/variables";

export type InputTypes = "text" | "password" | "email";

interface InputProps {
  type: InputTypes;
  onChange: (value: string) => void;
  placeholder?: string;
  value?: string;
  icon: InputIconProps;
  required?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
}

export const Input: React.FC<InputProps> = (props) => {
  const { icon, onChange, ...rest } = props;
  const { iconId, onClick } = icon;

  return (
    <InputWrapper>
      <InputField
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value);
        }}
        icon={icon}
        {...rest}
      />
      {iconId && <InputIcon iconId={iconId} onClick={onClick} />}
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  flex: 1 1 auto;
  position: relative;
`;

const InputField = styled.input<Pick<InputProps, "icon">>`
  width: 100%;
  height: 52px;
  padding: ${({ icon }) => (icon.iconId ? "0 60px 0 30px" : "0 30px")};
  background-color: ${WHITE_COLOR};
  border: 1px solid ${WHITE_COLOR};
  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px #0000001a;
  color: ${BLACK_COLOR};
  letter-spacing: 1px;

  @media screen and (max-width: ${MOBILE}) {
    height: 36px;
    padding: ${({ icon }) => (icon.iconId ? "0 30px 0 10px" : "0 10px")};
    font-size: 10px;
    line-height: 120%;
  }

  &:focus {
    border: 1px solid ${BLUE_COLOR};
  }
  &::placeholder {
    color: ${GRAY_COLOR};
  }
`;
