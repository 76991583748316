import axios, { AxiosError, AxiosResponse } from "axios";

export type SuccessApiResponse<T> = {
  success: boolean;
  data: T | null;
  message: string;
};

export type ErrorApiResponse = {
  success: boolean;
  status: number;
  errors: {
    message: string;
  };
};

export type ApiResponse<T> = SuccessApiResponse<T> | ErrorApiResponse;

type ApiRequest = {
  url: string;
  config?: { [key: string]: unknown } | null;
};

export const callApi: <T>(params: ApiRequest) => Promise<ApiResponse<T>> = ({
  url,
  config
}) => {
  return axios({
    url,
    ...config
  })
    .then((res: AxiosResponse) => res.data)
    .catch((err: AxiosError) => err.response?.data);
};
