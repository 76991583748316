import { Link } from "react-router-dom";
import styled from "styled-components";

interface CustomRouteLinkProps {
  to: string;
  className?: string;
  disabled?: boolean;
  children: React.ReactNode;
}

export const CustomRouteLink: React.FC<CustomRouteLinkProps> = (props) => {
  const { children, disabled, ...rest } = props;

  return (
    <LinkWrapper disabled={disabled}>
      <Link className="route-link" {...rest}>
        {children}
      </Link>
    </LinkWrapper>
  );
};

const LinkWrapper = styled.div<Pick<CustomRouteLinkProps, "disabled">>`
  .route-link {
    color: "inherit";
    text-decoration: "inherit";
    height: "min-content";
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  }
`;
