import { Field, Form, FormikProps } from "formik";

import {
  AuthCodeInput,
  AuthButton,
  AuthFormError
} from "components/authComponents";
import { FormFields } from "components/ui";

import { GetCodeInitialValues } from "types/AuthTypes";

export const GetCodeFormFields: React.FC<FormikProps<GetCodeInitialValues>> = (
  props
) => {
  const { values, setFieldValue, errors, isValid, dirty } = props;

  return (
    <Form>
      <FormFields>
        <Field
          name="code"
          id="code"
          placeholder="_"
          type="number"
          fields={6}
          value={values.code}
          onChange={(value: string) => {
            setFieldValue("code", value);
          }}
          component={AuthCodeInput}
        />

        <AuthFormError errors={errors} />
      </FormFields>

      <AuthButton text="Отправить" disabled={!dirty || !isValid} />
    </Form>
  );
};
