import * as Yup from "yup";

import {
  AuthFormHeader,
  AuthForm,
  AuthFormFooter,
  AuthContainer
} from "components/authComponents";

import { LoginInitialValues } from "types/AuthTypes";

const formInitialValues: LoginInitialValues = {
  login: "",
  password: ""
};

const formSchema = Yup.object().shape({
  login: Yup.string()
    .min(3, "Логин или пароль указан не верно")
    .max(30, "Логин или пароль указан не верно")
    .required("Заполните все поля"),
  password: Yup.string()
    .min(3, "Логин или пароль указан не верно")
    .max(30, "Логин или пароль указан не верно")
    .required("Заполните все поля")
});

const Login: React.FC = () => {
  return (
    <AuthContainer>
      <AuthFormHeader title="Вход пользователя" text="Укажите логин и пароль" />
      <AuthForm
        initialValues={formInitialValues}
        schema={formSchema}
        formType="login"
      />
      <AuthFormFooter />
    </AuthContainer>
  );
};

export default Login;
