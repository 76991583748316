import { PropsWithChildren } from "react";
import styled from "styled-components";

import { MOBILE } from "styles/variables";

export const AuthContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  padding: 0 20px;
  width: 590px;

  @media screen and (max-width: 530px) {
    width: 430px;
  }

  @media screen and (max-width: ${MOBILE}) {
    width: 300px;
  }
`;
