import { useMutation } from "@tanstack/react-query";

import routes from "api/routes";
import { callApi, ApiResponse, ErrorApiResponse } from "api/api";

import { useQueryResultHandler } from "hooks/custom/useQueryResultHandler";

type RegisterParams = {
  name: string;
  surname: string;
  email: string;
  password: string;
  password_confirmation: string;
};

const register = async (params: RegisterParams) => {
  const url = routes.auth.registration.api;

  const config = {
    method: "POST",
    data: {
      ...params
    }
  };

  const response = await callApi<RegisterParams>({
    url,
    config
  });

  return response;
};

export const useRegister = () => {
  const queryResultHandler = useQueryResultHandler();

  const responseHandler = (res: ApiResponse<RegisterParams>) => {
    queryResultHandler(res, { success: routes.auth.login.to });
  };

  const mutation = useMutation<
    ApiResponse<RegisterParams>,
    ErrorApiResponse,
    RegisterParams
  >((params: RegisterParams) => register(params), {
    onSuccess: (res: ApiResponse<RegisterParams>) => responseHandler(res),
    onError: (res: ErrorApiResponse) => responseHandler(res)
  });

  return mutation;
};
