import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { ApiResponse, ErrorApiResponse, SuccessApiResponse } from "api/api";

export const useQueryResultHandler = () => {
  const navigate = useNavigate();

  const queryResultHandler = <T>(
    res: ApiResponse<T>,
    to?: { success?: string; error?: string | false }
  ) => {
    if (res.success) {
      const data = res as SuccessApiResponse<T>;

      toast.success(data.message as string, { duration: 5000 });
      to?.success && navigate(to.success);
    } else {
      const data = res as ErrorApiResponse;

      toast.error(data.errors.message as string, {
        duration: 5000
      });
      to?.error && navigate(to.error);
    }
  };

  return queryResultHandler;
};
