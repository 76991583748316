import * as Yup from "yup";

import {
  AuthFormHeader,
  AuthForm,
  AuthFormFooter,
  AuthContainer
} from "components/authComponents";

import { RegistrationInitialValues } from "types/AuthTypes";

const formInitialValues: RegistrationInitialValues = {
  firstName: "",
  secondName: "",
  email: "",
  password: "",
  passwordConfirmation: ""
};

const formSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Данные указаны не верно")
    .max(50, "Данные указаны не верно")
    .required("Заполните все поля"),
  secondName: Yup.string()
    .min(2, "Данные указаны не верно")
    .max(50, "Данные указаны не верно")
    .required("Заполните все поля"),
  email: Yup.string()
    .test("email", "Данные указаны не верно", (value?: string) => {
      const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

      return !!value && emailRegExp.test(value);
    })
    .required("Заполните все поля"),
  password: Yup.string()
    .min(5, "Данные указаны не верно")
    .max(50, "Данные указаны не верно")
    .required("Заполните все поля"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password")], "Данные указаны не верно")
    .required("Заполните все поля")
});

const Registration: React.FC = () => {
  return (
    <AuthContainer>
      <AuthFormHeader
        title="Cоздание учетной записи"
        text="Опишите себя максимально четко, чтобы не было ошибок"
        isTelegramButton
      />
      <AuthForm
        initialValues={formInitialValues}
        schema={formSchema}
        formType="registration"
      />
      <AuthFormFooter />
    </AuthContainer>
  );
};

export default Registration;
