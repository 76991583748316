import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import screenSlice from "redux/slices/screenSlice";
import authSlice from "redux/slices/authSlice";
import userSlice from "redux/slices/userSlice";
import userTelegramSlice from "redux/slices/userTelegramSlice";

export const store = configureStore({
  reducer: {
    screen: screenSlice,
    auth: authSlice,
    user: userSlice,
    userTelegram: userTelegramSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
