import { useMutation } from "@tanstack/react-query";

import routes from "api/routes";
import { callApi, ApiResponse, ErrorApiResponse } from "api/api";

import { useQueryResultHandler } from "hooks/custom/useQueryResultHandler";

type RecoverPasswordParams = {
  email: string;
};

const recover = async (params: RecoverPasswordParams) => {
  const url = routes.auth.login.recovery.api;

  const config = {
    method: "POST",
    data: {
      ...params
    }
  };

  const response = await callApi<RecoverPasswordParams>({
    url,
    config
  });

  return response;
};

export const useRecoverPassword = () => {
  const queryResultHandler = useQueryResultHandler();

  const responseHandler = (res: ApiResponse<RecoverPasswordParams>) => {
    queryResultHandler(res, { error: routes.auth.login.recovery.to });
  };

  const mutation = useMutation<
    ApiResponse<RecoverPasswordParams>,
    ErrorApiResponse,
    RecoverPasswordParams
  >((params: RecoverPasswordParams) => recover(params), {
    onSuccess: (res: ApiResponse<RecoverPasswordParams>) =>
      responseHandler(res),
    onError: (res: ErrorApiResponse) => responseHandler(res)
  });

  return mutation;
};
