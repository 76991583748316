import { Button, Title } from "components/ui";

import { useAppSelector } from "redux/hooks/hooks";

import { BLACK_COLOR, WHITE_COLOR } from "styles/variables";

export interface AuthButtonProps {
  text: string;
  disabled?: boolean;
}

export const AuthButton: React.FC<AuthButtonProps> = ({ text, disabled }) => {
  const isMobile = useAppSelector((state) => state.screen.isMobile);

  return (
    <Button
      type="submit"
      backgroundColor={BLACK_COLOR}
      disabled={disabled}
      style={{ width: "100%", height: !isMobile ? "50px" : "30px" }}
    >
      <Title type={!isMobile ? "h3" : "h6"} color={WHITE_COLOR}>
        {text}
      </Title>
    </Button>
  );
};
