import { Routes, Route, Navigate } from "react-router-dom";
import styled from "styled-components";

import routes from "api/routes";

import { Header } from "components/header";
import EmailOrPhone from "./components/emailOrPhone/EmailOrPhone";
import Registration from "./components/registration/Registration";
import Telegram from "./components/telegram/Telegram";
import Login from "./components/login/Login";
import Recovery from "./components/recovery/Recovery";
import GetCode from "./components/getCode/GetCode";

import { HEADER_HEIGHT, MOBILE, MOBILE_HEADER_HEIGHT } from "styles/variables";

const Auth: React.FC = () => (
  <>
    <Header />
    <Container>
      <Routes>
        <Route path={routes.auth.contact.url} element={<EmailOrPhone />} />
        <Route path={routes.auth.contact.login.url} element={<GetCode />} />
        <Route path={routes.auth.login.url} element={<Login />} />
        <Route path={routes.auth.login.recovery.url} element={<Recovery />} />
        <Route path={routes.auth.registration.url} element={<Registration />} />
        <Route path={routes.auth.telegram.url} element={<Telegram />} />

        <Route
          path="*"
          element={<Navigate to={routes.auth.contact.url} replace />}
        />
      </Routes>
    </Container>
  </>
);

export default Auth;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: calc(70px + ${HEADER_HEIGHT}) 65px 70px;

  @media screen and (max-width: ${MOBILE}) {
    padding: calc(70px + ${MOBILE_HEADER_HEIGHT}) 65px 70px;
  }
`;
