import { Field, Form, FormikProps } from "formik";
import styled from "styled-components";

import {
  AuthInput,
  AuthButton,
  AuthFormError
} from "components/authComponents";
import { FormFields, Checkbox } from "components/ui";

import { useAppSelector } from "redux/hooks/hooks";

import { RegistrationInitialValues } from "types/AuthTypes";
import { MOBILE } from "styles/variables";

export const RegistrationFormFields: React.FC<
  FormikProps<RegistrationInitialValues>
> = (props) => {
  const { values, setFieldValue, errors, isValid, dirty } = props;
  const isMobile = useAppSelector((state) => state.screen.isMobile);

  return (
    <Form>
      <FormFields marginBottom={!isMobile ? "60px" : "20px"}>
        <PersonalInfo>
          <Field
            name="firstName"
            id="firstName"
            placeholder="Имя"
            type="text"
            value={values.firstName}
            onChange={(value: string) => {
              setFieldValue("firstName", value);
            }}
            required
            component={AuthInput}
          />
          <Field
            name="secondName"
            id="secondName"
            placeholder="Фамилия"
            type="text"
            value={values.secondName}
            onChange={(value: string) => {
              setFieldValue("secondName", value);
            }}
            required
            component={AuthInput}
          />
        </PersonalInfo>
        <Field
          name="email"
          id="email"
          placeholder="E-mail"
          type="email"
          value={values.email}
          onChange={(value: string) => {
            setFieldValue("email", value);
          }}
          required
          component={AuthInput}
        />
        <Field
          name="password"
          id="password"
          placeholder="Пароль"
          type="password"
          value={values.password}
          onChange={(value: string) => {
            setFieldValue("password", value);
          }}
          required
          component={AuthInput}
        />
        <Field
          name="passwordConfirmation"
          id="passwordConfirmation"
          placeholder="Повторить пароль"
          type="password"
          value={values.passwordConfirmation}
          onChange={(value: string) => {
            setFieldValue("passwordConfirmation", value);
          }}
          required
          component={AuthInput}
        />

        <CheckboxWrapper>
          <Checkbox name="rememberMe" id="rememberMe" text="Запомнить меня" />
        </CheckboxWrapper>

        <AuthFormError errors={errors} />
      </FormFields>

      <AuthButton text="Зарегистрироваться" disabled={!dirty || !isValid} />
    </Form>
  );
};

const PersonalInfo = styled.div`
  display: flex;
  gap: 16px;

  @media screen and (max-width: ${MOBILE}) {
    gap: 20px;
  }
`;

const CheckboxWrapper = styled.div`
  @media screen and (max-width: ${MOBILE}) {
    margin-top: 9px;
  }
`;
