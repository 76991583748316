export const PRIMARY_BG_COLOR = "#E9E9E9";
export const SECONDARY_BG_COLOR = "#F8FAFB";

export const BLACK_COLOR = "#000000";
export const WHITE_COLOR = "#FFFFFF";
export const DARK_GRAY_COLOR = "#51525A";
export const LIGHT_GRAY_COLOR = "#D2D0D7";
export const GRAY_COLOR = "#9AA2A9";
export const OUR_LOGO_COLOR = "#1563F6";
export const LIGHT_BLUE_COLOR = "#74A4A7";
export const BLUE_COLOR = "#2695FC";
export const GREEN_COLOR = "#2ED47A";
export const RED_COLOR = "#F7685B";
export const YELLOW_COLOR = "#FFB946";

export const HEADER_HEIGHT = "60px";
export const MOBILE_HEADER_HEIGHT = "36px";

export const DESKTOP = "1441px";
export const LAPTOP = "1025px";
export const MOBILE = "429px";
