import { Field, Form, FormikProps } from "formik";
import styled from "styled-components";

import {
  AuthInput,
  AuthFormError,
  TelegramButton,
  LoginButton,
  GetCodeButton
} from "components/authComponents";
import { FormFields } from "components/ui";

import { useAppDispatch } from "redux/hooks/hooks";
import { setUser } from "redux/slices/userSlice";

import { EmailOrPhoneInitialValues } from "types/AuthTypes";

export const EmailOrPhoneFormFields: React.FC<
  FormikProps<EmailOrPhoneInitialValues>
> = (props) => {
  const { values, setFieldValue, errors, isValid, dirty, handleSubmit } = props;
  const dispatch = useAppDispatch();

  return (
    <Form>
      <FormFields>
        <Field
          name="emailOrPhone"
          id="emailOrPhone"
          placeholder="E-mail или номер телефона"
          type="emailOrPhone"
          value={values.emailOrPhone}
          onChange={(value: string) => {
            setFieldValue("emailOrPhone", value);
          }}
          required
          component={AuthInput}
        />

        <AuthFormError errors={errors} />
      </FormFields>

      <ButtonsWrapper>
        <OptionalButtons>
          <GetCodeButton
            disabled={!dirty || !isValid}
            onClick={() => {
              dispatch(setUser(values));
              handleSubmit();
            }}
          />
          <LoginButton />
        </OptionalButtons>

        <TelegramButton />
      </ButtonsWrapper>
    </Form>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OptionalButtons = styled.div`
  display: flex;
  gap: 20px;
`;
