import { CSSProperties, PropsWithChildren } from "react";
import styled from "styled-components";

import { BLACK_COLOR } from "styles/variables";

export type TextTypes = "p1" | "p2" | "p3" | "p4" | "p5" | "p6";

interface TextProps {
  type: TextTypes;
  color?: string;
  style?: CSSProperties;
  className?: string;
}

export const Text: React.FC<PropsWithChildren<TextProps>> = (props) => {
  const { children, ...rest } = props;

  return <TextElement {...rest}>{children}</TextElement>;
};

const TextElement = styled.div<TextProps>`
  font-weight: 400;
  color: ${({ color }) => color || BLACK_COLOR};
  font-size: ${({ type }) => {
    switch (type) {
      case "p1":
        return "18px";
      case "p2":
        return "16px";
      case "p3":
        return "14px";
      case "p4":
        return "10px";
      case "p5":
        return "8px";
      case "p6":
        return "5px";
    }
  }};
  line-height: ${({ type }) => {
    switch (type) {
      case "p1":
        return "120%";
      case "p2":
        return "120%";
      case "p3":
        return "120%";
      case "p4":
        return "120%";
      case "p5":
        return "120%";
      case "p6":
        return "120%";
    }
  }};
  letter-spacing: ${({ type }) => {
    switch (type) {
      case "p1":
        return "1px";
      case "p2":
        return "1px";
      case "p3":
        return "1px";
      case "p4":
        return "1px";
      case "p5":
        return "1px";
      case "p6":
        return "normal";
    }
  }};
`;
