import { useEffect } from "react";
import { Field, Form, FormikProps } from "formik";
import styled from "styled-components";

import {
  AuthInput,
  AuthButton,
  AuthFormError
} from "components/authComponents";
import { FormFields } from "components/ui";

import { useAppSelector } from "redux/hooks/hooks";

import { TelegramInitialValues } from "types/AuthTypes";
import { MOBILE } from "styles/variables";

export const TelegramFormFields: React.FC<
  FormikProps<TelegramInitialValues>
> = (props) => {
  const { values, setFieldValue, errors, isValid } = props;
  const userTelegram = useAppSelector((state) => state.userTelegram.telegram);

  useEffect(() => {
    setFieldValue("firstName", userTelegram.first_name);
    setFieldValue("secondName", userTelegram.last_name);
    setFieldValue("telegramID", userTelegram.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form>
      <FormFields>
        <PersonalInfo>
          <Field
            name="firstName"
            id="firstName"
            placeholder="Имя"
            type="text"
            value={values.firstName}
            disabled
            required
            component={AuthInput}
          />
          <Field
            name="secondName"
            id="secondName"
            placeholder="Фамилия"
            type="text"
            value={values.secondName}
            disabled
            component={AuthInput}
          />
        </PersonalInfo>

        <Field
          name="telegramID"
          id="telegramID"
          placeholder="ID"
          type="telegramID"
          value={userTelegram.id || values.telegramID}
          disabled
          required
          component={AuthInput}
        />

        <AuthFormError errors={errors} />
      </FormFields>

      <AuthButton text="Войти" disabled={!isValid} />
    </Form>
  );
};

const PersonalInfo = styled.div`
  display: flex;
  gap: 16px;

  @media screen and (max-width: ${MOBILE}) {
    gap: 20px;
  }
`;
