import styled from "styled-components";

import { TelegramButton } from "components/authComponents";
import { Title, Text } from "components/ui";

import { useAppSelector } from "redux/hooks/hooks";

import {
  GRAY_COLOR,
  LIGHT_GRAY_COLOR,
  PRIMARY_BG_COLOR,
  MOBILE
} from "styles/variables";

export interface AuthFormHeaderProps {
  title: string;
  text: string;
  isTelegramButton?: boolean;
}

export const AuthFormHeader: React.FC<AuthFormHeaderProps> = ({
  title,
  text,
  isTelegramButton
}) => {
  const isMobile = useAppSelector((state) => state.screen.isMobile);

  return (
    <AuthFormHeaderWrapper>
      <Content>
        <Title type={!isMobile ? "h1" : "h4"}>{title}</Title>
        <Text type={!isMobile ? "p3" : "p5"} color={GRAY_COLOR}>
          {text}
        </Text>
      </Content>

      {isTelegramButton && (
        <CustomContent>
          <TelegramButton />

          <Or>
            <Text type={!isMobile ? "p2" : "p4"} color={LIGHT_GRAY_COLOR}>
              Или
            </Text>
          </Or>
        </CustomContent>
      )}
    </AuthFormHeaderWrapper>
  );
};

const AuthFormHeaderWrapper = styled.div`
  position: relative;
  border-bottom: 1px solid ${LIGHT_GRAY_COLOR};
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;

  @media screen and (max-width: ${MOBILE}) {
    gap: 6px;
    margin-bottom: 16px;
  }
`;

const CustomContent = styled.div`
  margin-bottom: 30px;

  @media screen and (max-width: ${MOBILE}) {
    margin-bottom: 16px;
  }
`;

const Or = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 1;
  padding: 0 24.5px;
  background-color: ${PRIMARY_BG_COLOR};
  text-transform: lowercase;

  @media screen and (max-width: ${MOBILE}) {
    padding: 0 20px;
  }
`;
