import { useNavigate } from "react-router-dom";
import TelegramLoginButton from "telegram-login-button";
import styled from "styled-components";

import routes from "api/routes";

import { useAppDispatch } from "redux/hooks/hooks";
import { setTelegram } from "redux/slices/userTelegramSlice";

import { TelegramLoginParams } from "types/AuthTypes";

export const TelegramButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleTelegramResponse = (user: TelegramLoginParams) => {
    dispatch(setTelegram({ telegram: user }));
    navigate(routes.auth.telegram.to);
  };

  return (
    <TelegramLoginButtonWrapper>
      <TelegramLoginButton
        dataOnauth={handleTelegramResponse}
        botName="RmnvPoolTestBot"
        usePic={false}
        cornerRadius={5}
        lang="ru"
      />
    </TelegramLoginButtonWrapper>
  );
};

const TelegramLoginButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
`;
