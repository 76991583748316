import * as Yup from "yup";

import {
  AuthFormHeader,
  AuthForm,
  AuthFormFooter,
  AuthContainer
} from "components/authComponents";

import { RecoveryInitialValues } from "types/AuthTypes";

const formInitialValues: RecoveryInitialValues = {
  emailOrPhone: ""
};

const formSchema = Yup.object().shape({
  emailOrPhone: Yup.string()
    .test(
      "email-or-phone",
      "E-mail или номер телефона указан не верно",
      (value?: string) => {
        const phoneRegExp = /^\+7 \d{3} \d{3}-\d{2}-\d{2}$/;
        const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        return !!value && (phoneRegExp.test(value) || emailRegExp.test(value));
      }
    )
    .required("E-mail или номер телефона указан не верно")
});

const Recovery: React.FC = () => {
  return (
    <AuthContainer>
      <AuthFormHeader
        title="Восстановление доступа"
        text="Укажите почту или номер телефона, на которые необходимо отправить код подтверждения"
      />
      <AuthForm
        initialValues={formInitialValues}
        schema={formSchema}
        formType="recovery"
      />
      <AuthFormFooter />
    </AuthContainer>
  );
};

export default Recovery;
