import { Navigate } from "react-router-dom";
import * as Yup from "yup";

import routes from "api/routes";

import {
  AuthFormHeader,
  AuthForm,
  AuthFormFooter,
  AuthContainer
} from "components/authComponents";

import { useAppSelector } from "redux/hooks/hooks";

import { TelegramInitialValues } from "types/AuthTypes";

const formInitialValues: TelegramInitialValues = {
  firstName: "",
  secondName: "",
  telegramID: ""
};

const formSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Данные указаны не верно")
    .max(50, "Данные указаны не верно"),
  secondName: Yup.string()
    .min(2, "Данные указаны не верно")
    .max(50, "Данные указаны не верно"),
  telegramID: Yup.string()
    .min(5, "Данные указаны не верно")
    .max(12, "Данные указаны не верно")
});

const Telegram: React.FC = () => {
  const userTelegram = useAppSelector((state) => state.userTelegram.telegram);

  if (!userTelegram.auth_date) {
    return <Navigate to={routes.auth.contact.to} replace />;
  }

  return (
    <AuthContainer>
      <AuthFormHeader
        title="Вход с помощью Telegram"
        text="Укажите все данные"
      />
      <AuthForm
        initialValues={formInitialValues}
        schema={formSchema}
        formType="telegram"
      />
      <AuthFormFooter />
    </AuthContainer>
  );
};

export default Telegram;
