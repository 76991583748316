import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ScreenSlice {
  isMobile: boolean
}

const initialState: ScreenSlice = {
  isMobile: false,
};

export const screenSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setScreen: (state, action: PayloadAction<ScreenSlice>) => {
      state.isMobile = action.payload.isMobile;
    },
  },
});

export const { setScreen } = screenSlice.actions;
export default screenSlice.reducer;
