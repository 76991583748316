import { FormikProps } from "formik";

import { EmailOrPhoneFormFields } from "pages/auth/components/emailOrPhone/EmailOrPhoneFormFields";
import { GetCodeFormFields } from "pages/auth/components/getCode/GetCodeFormFields";
import { LoginFormFields } from "pages/auth/components/login/LoginFormFields";
import { RecoveryFormFields } from "pages/auth/components/recovery/RecoveryFormFields";
import { RegistrationFormFields } from "pages/auth/components/registration/RegistrationFormFields";
import { TelegramFormFields } from "pages/auth/components/telegram/TelegramFormFields";

import { FormType } from "./AuthForm";
import {
  AuthInitialValues,
  EmailOrPhoneInitialValues,
  GetCodeInitialValues,
  LoginInitialValues,
  RecoveryInitialValues,
  RegistrationInitialValues,
  TelegramInitialValues
} from "types/AuthTypes";

export const getFormFields = (
  formType: FormType,
  props: FormikProps<AuthInitialValues>
) => {
  switch (formType) {
    case "emailOrPhone":
      return (
        <EmailOrPhoneFormFields
          {...(props as FormikProps<EmailOrPhoneInitialValues>)}
        />
      );
    case "getCode":
      return (
        <GetCodeFormFields {...(props as FormikProps<GetCodeInitialValues>)} />
      );
    case "login":
      return (
        <LoginFormFields {...(props as FormikProps<LoginInitialValues>)} />
      );
    case "recovery":
      return (
        <RecoveryFormFields
          {...(props as FormikProps<RecoveryInitialValues>)}
        />
      );
    case "registration":
      return (
        <RegistrationFormFields
          {...(props as FormikProps<RegistrationInitialValues>)}
        />
      );
    case "telegram":
      return (
        <TelegramFormFields
          {...(props as FormikProps<TelegramInitialValues>)}
        />
      );
  }
};
