import { CSSProperties, useRef } from "react";
import styled from "styled-components";

import { Text } from "../text/Text";

import CheckedIcon from "assets/icons/checked.svg";
import {
  BLUE_COLOR,
  DARK_GRAY_COLOR,
  GRAY_COLOR,
  MOBILE
} from "styles/variables";

interface CheckboxProps {
  id: string;
  name: string;
  text: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
  className?: string;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { id, text, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <CheckboxWrapper>
      <Label htmlFor={id}>
        <CheckboxInput type="checkbox" id={id} ref={inputRef} {...rest} />
        <CustomCheckbox className="checkbox__fake-btn" />
        <Text type="p4" color={GRAY_COLOR}>
          {text}
        </Text>
      </Label>
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled.div`
  width: fit-content;
`;

const Label = styled.label`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const CheckboxInput = styled.input`
  display: none;

  &:checked + .checkbox__fake-btn {
    border: none;
    background-color: ${BLUE_COLOR};

    &::before {
      opacity: 1;
    }
  }
`;

const CustomCheckbox = styled.div`
  position: relative;
  top: -2px;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid ${DARK_GRAY_COLOR};
  border-radius: 50%;
  box-shadow:
    0.5px 0.5px 1px 0px #0000001a inset,
    -0.5px -0.5px 1px 0px #0000001a inset,
    0px 0px 1px 0px #000000cc;

  @media screen and (max-width: ${MOBILE}) {
    width: 16px;
    height: 16px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: url(${CheckedIcon});
    background-repeat: no-repeat;
    background-position: center;
  }
`;
