const api = process.env.REACT_APP_API_URL;

const routes = {
  auth: {
    url: "/auth/*",

    contact: {
      url: "contact",
      to: "/auth/contact",
      api: `${api}/contact`,
      login: {
        url: "contact/login",
        to: "/auth/contact/login",
        api: `${api}/contact/login`
      }
    },
    login: {
      url: "login",
      to: "/auth/login",
      api: `${api}/auth/login`,
      recovery: {
        url: "login/recovery",
        to: "/auth/login/recovery",
        api: `${api}/recover_password`
      }
    },
    registration: {
      url: "registration",
      to: "/auth/registration",
      api: `${api}/auth/register`
    },
    // reset: `${api}/reset_password`,
    telegram: {
      url: "telegram",
      to: "/auth/telegram",
      api: `${api}/telegram/login`
    }
  }
};

export default routes;
