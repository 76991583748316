import styled from "styled-components";

import SvgSelector from "assets/SvgSelector";
import { GRAY_COLOR, MOBILE } from "styles/variables";

export type InputIconProps = {
  iconId: string;
  onClick?: () => void;
};

export const InputIcon: React.FC<InputIconProps> = (props) => {
  const { iconId, ...rest } = props;

  return (
    <InputIconElement {...rest}>
      <SvgSelector id={iconId} />
    </InputIconElement>
  );
};

const InputIconElement = styled.div<Omit<InputIconProps, "iconId">>`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  padding-right: 30px;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "auto")};

  svg {
    width: 20px;
    height: 20px;
    fill: ${GRAY_COLOR};
  }

  @media screen and (max-width: ${MOBILE}) {
    width: 30px;
    padding-right: 10px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
`;
