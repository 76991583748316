import { CSSProperties, PropsWithChildren } from "react";

interface CustomLinkProps {
  href: string;
  target?: "_blank" | "_parent" | "_self" | "_top";
  rel?: "noreferrer";
  style?: CSSProperties;
  className?: string;
}

export const CustomLink: React.FC<PropsWithChildren<CustomLinkProps>> = (
  props
) => {
  const { children, ...rest } = props;

  return <a {...rest}>{children}</a>;
};
