import styled from "styled-components";

import routes from "api/routes";

import { Title, RouteButton } from "components/ui";

import { useAppSelector } from "redux/hooks/hooks";

import { BLACK_COLOR, DARK_GRAY_COLOR, WHITE_COLOR } from "styles/variables";

type GetCodeButtonProps = {
  disabled: boolean;
  onClick: () => void;
};

export const GetCodeButton: React.FC<GetCodeButtonProps> = ({
  disabled,
  onClick
}) => {
  const isMobile = useAppSelector((state) => state.screen.isMobile);

  return (
    <ButtonWrapper>
      <RouteButton
        to={routes.auth.contact.login.to}
        disabled={disabled}
        onClick={onClick}
        className="button-code"
        style={{ width: "100%", height: !isMobile ? "50px" : "30px" }}
      >
        <Title
          type={!isMobile ? "h3" : "h6"}
          color={DARK_GRAY_COLOR}
          className="button-text"
        >
          Получить код
        </Title>
      </RouteButton>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  flex: 1 1 auto;

  .button-code:hover {
    background-color: ${BLACK_COLOR};

    .button-text {
      color: ${WHITE_COLOR};
    }
  }
`;
