import { matchPath } from "react-router-dom";
import styled from "styled-components";

import routes from "api/routes";

import { Text, CustomLink, CustomRouteLink } from "components/ui";

import { useAppSelector } from "redux/hooks/hooks";

import SvgSelector from "assets/SvgSelector";
import {
  OUR_LOGO_COLOR,
  GRAY_COLOR,
  WHITE_COLOR,
  BLUE_COLOR,
  MOBILE
} from "styles/variables";

const ForgotPasswordLink: React.FC<{ isMobile: boolean }> = ({ isMobile }) => (
  <CustomRouteLink to={routes.auth.login.recovery.to}>
    <Text
      type={!isMobile ? "p4" : "p5"}
      color={GRAY_COLOR}
      className="recovery__link"
    >
      Забыли пароль?
    </Text>
  </CustomRouteLink>
);
const PersonalDataProcessingLink: React.FC<{ isMobile: boolean }> = ({
  isMobile
}) => (
  <Text type={!isMobile ? "p4" : "p5"} color={GRAY_COLOR}>
    Нажимая кнопку я даю согласие на{" "}
    <CustomLink
      href="#"
      style={{ color: BLUE_COLOR }}
      className="recovery__link"
    >
      обработку персональных данных
    </CustomLink>
  </Text>
);

export const AuthFormFooter: React.FC = () => {
  const isMobile = useAppSelector((state) => state.screen.isMobile);
  const isLoginPage = !!matchPath(location.pathname, "/auth/login");

  return (
    <>
      <Recovery isLoginPage={isLoginPage}>
        {isLoginPage ? (
          <ForgotPasswordLink isMobile={isMobile} />
        ) : (
          <PersonalDataProcessingLink isMobile={isMobile} />
        )}
      </Recovery>
      <LoginFooter>
        <CustomLink
          href="https://codedunes.com/"
          target="_blank"
          rel="noreferrer"
        >
          <MadeBy>
            <SvgSelector id="codeDunes" className="madeBy__icon" />
            <Text type="p5" color={GRAY_COLOR} className="madeBy__text">
              made by codedunes
            </Text>
          </MadeBy>
        </CustomLink>
      </LoginFooter>
    </>
  );
};

const Recovery = styled.div<{ isLoginPage?: boolean }>`
  width: fit-content;
  margin: ${({ isLoginPage }) => (isLoginPage ? "0" : "0 auto")};
  margin-bottom: 20px;

  > div {
    text-align: ${({ isLoginPage }) => (isLoginPage ? "left" : "center")};
  }

  @media screen and (max-width: ${MOBILE}) {
    margin-bottom: 16px;
  }

  .recovery__link:hover {
    color: ${BLUE_COLOR};
    text-decoration: underline;
  }
`;

const LoginFooter = styled.div`
  width: fit-content;
  margin: 0 auto;
`;

const MadeBy = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 5px 10px;
  border: 1px solid ${GRAY_COLOR};
  border-radius: 5px;

  .madeBy__text {
    position: relative;
    top: 1px;
    letter-spacing: normal;
  }

  &:hover {
    background-color: ${OUR_LOGO_COLOR};
    border: 1px solid ${OUR_LOGO_COLOR};

    .madeBy__text {
      color: ${WHITE_COLOR};
    }
    .madeBy__icon > path {
      fill: ${WHITE_COLOR};
    }
  }
`;
