import { createGlobalStyle } from "styled-components";

import evolventaRegular from "../assets/fonts/Evolventa-Regular.ttf";
import evolventaBold from "../assets/fonts/Evolventa-Bold.ttf";

import { GRAY_COLOR, PRIMARY_BG_COLOR, WHITE_COLOR } from "styles/variables";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Evolventa;
    src: url(${evolventaRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: Evolventa;
    src: url(${evolventaBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    scrollbar-width: thin;
    scrollbar-color: ${GRAY_COLOR} ${WHITE_COLOR};
  }

  body {
    background-color: ${PRIMARY_BG_COLOR};
    font-family: Evolventa, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: ${WHITE_COLOR};
      border: 0.2px solid ${GRAY_COLOR};
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${GRAY_COLOR};
      border-radius: 5px;
    }
  }

  input {
    font-family: inherit;
    border: none;
  }
  input:focus {
    outline: none;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px ${WHITE_COLOR} inset;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    outline: none;
    border: none;
  }
`;
