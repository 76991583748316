import { LoginParams } from "types/AuthTypes";

export const isPasswordLogin = (params: LoginParams) => {
  const keys = Object.keys(params);

  return keys.includes("email") && keys.includes("password");
};

export const isTelegramLogin = (params: LoginParams) => {
  const keys = Object.keys(params);

  return (
    keys.includes("auth_date") &&
    keys.includes("first_name") &&
    keys.includes("id") &&
    keys.includes("hash")
  );
};

export const isContactLogin = (params: LoginParams) => {
  const keys = Object.keys(params);

  return keys.includes("email") && keys.includes("token");
};
