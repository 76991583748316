import ReactCodeInput from "react-code-input";
import styled from "styled-components";

import { BLACK_COLOR, BLUE_COLOR, WHITE_COLOR } from "styles/variables";

export interface AuthCodeInputProps {
  type: "number";
  value: string;
  name: string;
  onChange: (value: string) => void;
  placeholder: string;
  fields: number;
}

export const AuthCodeInput: React.FC<AuthCodeInputProps> = (props) => {
  const { type, value, name, onChange, placeholder, fields } = props;

  return (
    <InputWrapper>
      <ReactCodeInput
        type={type}
        fields={fields}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoFocus={false}
        filterKeyCodes={[187, 189, 107, 109]} // - + (and NumLock) chars
        filterChars={[".", ","]}
        name={name}
        inputMode="tel"
        className="code"
      />
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  margin: 0 auto;

  .code {
    & > input {
      width: 40px;
      height: 40px;
      margin-right: 8px;
      background-color: ${WHITE_COLOR};
      box-shadow: 0px 3px 4px 0px #0000001a;
      border-radius: 5px;
      color: ${BLACK_COLOR};
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 3px;

      &:focus {
        border: 1px solid ${BLUE_COLOR};
      }

      &::placeholder {
        color: ${BLACK_COLOR};
      }
    }
  }
`;
