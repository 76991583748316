import styled from "styled-components";

import routes from "api/routes";

import { Title, RouteButton } from "components/ui";

import { useAppSelector } from "redux/hooks/hooks";

import { BLACK_COLOR, DARK_GRAY_COLOR, WHITE_COLOR } from "styles/variables";

export const LoginButton: React.FC = () => {
  const isMobile = useAppSelector((state) => state.screen.isMobile);

  return (
    <ButtonWrapper>
      <RouteButton
        to={routes.auth.login.to}
        className="button-login"
        style={{ width: "100%", height: !isMobile ? "50px" : "30px" }}
      >
        <Title
          type={!isMobile ? "h3" : "h6"}
          color={DARK_GRAY_COLOR}
          className="button-text"
        >
          Войти с паролем
        </Title>
      </RouteButton>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  flex: 1 1 auto;

  .button-login:hover {
    background-color: ${BLACK_COLOR};

    .button-text {
      color: ${WHITE_COLOR};
    }
  }
`;
