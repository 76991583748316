import { Field, Form, FormikProps } from "formik";

import {
  AuthInput,
  AuthButton,
  AuthFormError
} from "components/authComponents";
import { FormFields } from "components/ui";

import { LoginInitialValues } from "types/AuthTypes";

export const LoginFormFields: React.FC<FormikProps<LoginInitialValues>> = (
  props
) => {
  const { values, setFieldValue, errors, isValid, dirty } = props;

  return (
    <Form>
      <FormFields>
        <Field
          name="login"
          id="login"
          placeholder="Логин"
          type="text"
          value={values.login}
          onChange={(value: string) => {
            setFieldValue("login", value);
          }}
          required
          component={AuthInput}
        />
        <Field
          name="password"
          id="password"
          placeholder="Пароль"
          type="password"
          value={values.password}
          onChange={(value: string) => {
            setFieldValue("password", value);
          }}
          required
          component={AuthInput}
        />

        <AuthFormError errors={errors} />
      </FormFields>

      <AuthButton text="Войти" disabled={!dirty || !isValid} />
    </Form>
  );
};
