import { CSSProperties, PropsWithChildren } from "react";
import styled from "styled-components";

import { WHITE_COLOR } from "styles/variables";

interface ButtonProps {
  backgroundColor?: string;
  type?: "submit";
  disabled?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  className?: string;
}

export const Button: React.FC<PropsWithChildren<ButtonProps>> = (props) => {
  const { children, ...rest } = props;

  return <ButtonElement {...rest}>{children}</ButtonElement>;
};

const ButtonElement = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor || WHITE_COLOR};
  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px #0000001a;

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: auto;
  }
`;
