import { Routes, Route } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Toaster } from "react-hot-toast";

import Auth from "./pages/auth/Auth";

import { useAppDispatch } from "redux/hooks/hooks";
import { setScreen } from "redux/slices/screenSlice";

import { GlobalStyle } from "styles/global";
import { MOBILE } from "styles/variables";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ maxWidth: MOBILE });

  dispatch(setScreen({ isMobile }));

  return (
    <>
      <Routes>
        <Route path="/auth/*" element={<Auth />} />
      </Routes>

      <Toaster />

      <GlobalStyle />
    </>
  );
};

export default App;
